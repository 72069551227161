import moment from 'moment';

export function formatCpc(num, def = '0') {
	return this.formatNumber(num, def, 3);
}

export function formatNumber(num, def = '0', digits = 2) {
	if (!num) {
		return def;
	}

	return (+num.toFixed(digits)).toLocaleString('en-US');
}

export function formatNumberIfExist(val, add = '') {
	return val ? `${formatNumber(val)}${add}` : '—';
}

export function formatNumberCompact(val) {
	const formatter = Intl.NumberFormat('en', { notation: 'compact' });

	return formatter.format(val);
}

export function decimalToPercentage(value = 0, max_value = 100) {
	return +formatNumber(Math.min(Math.abs(+formatNumber(+value)) * 100, max_value));
}

export function percentageToDecimal(value = 0) {
	return +formatNumber(Math.abs(+value) / 100);
}

export function formatNumberSign(number, def, digits) {
	number = +number.toFixed(4);
	const sign = number > 0 ? '+' : '';
	const result = this.formatNumber(number, def, digits);

	return sign + result;
}

export function formatUSD(amount, digits = 2) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: digits,
	});

	return amount ? formatter.format(amount) : formatter.format(0);
}

export function formatCompactUSD(amount) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		notation: 'compact',
		compactDisplay: 'short',
		minimumFractionDigits: 0,
	});

	return amount ? formatter.format(amount) : formatter.format(0);
}

export function formatImageLink(link) {
	if (typeof link !== 'string') return '';
	return (link.startsWith('crlib') ? '' : '/creatives') + '/' + link;
}

export function convertDate(date, format = 'YYYY-MM-DD') {
	return moment.utc(date).format(format);
}

export function dateDiffAsDay(date) {
	return moment().diff(moment(date), 'days');
}

export function formatAccountType(accountType) {
	if (accountType === 'adv') {
		return 'Advertiser';
	} else if (accountType === 'adm') {
		return 'Admin';
	} else if (accountType === 'pub') {
		return 'Publisher';
	}
}

export function formatFormat(formatId) {
	if (formatId === 1) {
		return 'Push';
	} else if (formatId === 2) {
		return 'OnClick';
	} else if (formatId === 3) {
		return 'In-Page Push';
	} else if (formatId === 4) {
		return 'Native';
	} else if (formatId === 5) {
		return 'Calendar';
	} else if (formatId === 6) {
		return 'Interstitial';
	} else if (formatId === 7) {
		return 'Direct click';
	}
}

export function formatBidModel(bidId) {
	if (bidId === 1) {
		return 'CPC';
	} else if (bidId === 2) {
		return 'CPM';
	} else if (bidId === 3) {
		return 'CPA';
	} else if (bidId === 4) {
		return 'Parsed';
	} else if (bidId === 5) {
		return 'SmartCPC';
	} else if (bidId === 6) {
		return 'SmartCPM';
	} else if (bidId === 7) {
		return 'CPA Goal CPC';
	} else if (bidId === 8) {
		return 'CPA Goal CPM';
	} else if (bidId === 9) {
		return 'CPC (Direct click)';
	} else if (bidId === 10) {
		return 'SmartCPC (Direct click)';
	}
}

export function formatAdSecure(modAdsecure) {
	return capitalizeWord(modAdsecure) || 'Wait';
}

export function capitalizeWords(words) {
	if (typeof words !== 'string') return '';
	return words.trim().split('_').join(' ').split(' ').map(this.capitalizeWord).join(' ');
}

export function formatURL(url) {
	if (typeof url !== 'string') return '';
	return url.replace(/^\/\/|^.*?:(\/\/)?/, '');
}

export function capitalizeWord(word) {
	if (typeof word !== 'string') return '';
	return word.slice(0, 1).toUpperCase() + word.slice(1);
}

export function updateFilterByValueAndKey(value, key, path, filterType, filterName) {
	if (!filterName) {
		// eslint-disable-next-line no-console
		console.error('FilterName is required!');
		return;
	}

	if (!filterType) {
		// eslint-disable-next-line no-console
		console.error('FilterType is required!');
		return;
	}

	if (!path) {
		// eslint-disable-next-line no-console
		console.error('path is required!');
		return;
	}

	if (!localStorage.getItem(filterName) || localStorage.getItem(filterName) === 'null') {
		localStorage.setItem(filterName, JSON.stringify({}));
	}

	const filters = JSON.parse(localStorage.getItem(filterName));

	if (
		(filterName === 'multiselectFilters' && value?.length) ||
		(filterName !== 'multiselectFilters' && (value || value === null || value?.length))
	) {
		if (!filters[path]) {
			filters[path] = {};
		}

		filters[path][key] = {
			type: filterType,
			value: value,
		};
	} else {
		if (filters[path] && filters[path][key]) {
			delete filters[path][key];
		}

		if (filters[path] && !Object.keys(filters[path]).length) {
			delete filters[path];
		}
	}

	localStorage.setItem(filterName, JSON.stringify(filters));
}
